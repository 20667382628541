const products = [{ name: 'Haus AI', releaseDate: '2024.12.20', url: 'https://haus-ai.com' }]

const companyInfo = [
  { label: 'NAME', value: 'Null Studio LLC' },
  { label: 'DESCRIPTION', value: 'A software company based in Miami' },
  { label: 'EMAIL', value: 'contact@nullstudio.co' },
]

export function ComputersOverlay() {
  return (
    <div className='absolute enset-0 w-full h-full z-10 flex flex-col p-7'>
      <div className='md:hidden w-full mb-4'>
        <CompanyInfoTable />
      </div>

      <div className='hidden md:block absolute top-7 left-7'>
        <CompanyInfoTable />
      </div>

      {/* <div className='w-full mt-auto md:flex md:justify-end'> */}
      {/*   <ProductsTable /> */}
      {/* </div> */}
    </div>
  )
}

function CompanyInfoTable() {
  return (
    <div className='text-xs text-[#52CEFF] font-mono w-full'>
      <table className='border-collapse w-full'>
        <thead>
          <tr>
            <th className='border border-[#52CEFF] px-2 py-1' colSpan={2}>
              COMPANY INFO
            </th>
          </tr>
        </thead>
        <tbody>
          {companyInfo.map((info) => (
            <tr key={info.label}>
              <td className='border border-[#52CEFF] px-2 py-1'>{info.label}</td>
              <td className='border border-[#52CEFF] px-2 py-1'>{info.value}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

function ProductsTable() {
  return (
    <div className='text-xs text-[#52CEFF] font-mono w-full md:w-auto'>
      <table className='border-collapse w-full md:w-auto'>
        <thead>
          <tr>
            <th className='border border-[#52CEFF] px-2 py-1' colSpan={3}>
              PRODUCTS
            </th>
          </tr>
          <tr>
            <th className='border border-[#52CEFF] px-2 py-1'>NAME</th>
            <th className='border border-[#52CEFF] px-2 py-1'>SITE URL</th>
            <th className='border border-[#52CEFF] px-2 py-1'>RELEASE DATE</th>
          </tr>
        </thead>
        <tbody>
          {products.map((product) => (
            <tr
              key={product.name}
              className='hover:bg-[#52CEFF] hover:bg-opacity-20 transition-colors duration-200 cursor-pointer'
              onClick={() => window.open(product.url, '_blank', 'noopener,noreferrer')}
            >
              <td className='border border-[#52CEFF] px-2 py-1'>{product.name}</td>
              <td className='border border-[#52CEFF] px-2 py-1'>{product.url}</td>
              <td className='border border-[#52CEFF] px-2 py-1'>{product.releaseDate}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}
